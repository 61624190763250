import './css/bootstrap.min.css';
import './css/idangerous.swiper.css';
import './css/font-awesome.min.css';
import './css/style.css';
import './css/custom.css';
import './floating-buttons/css/jquery-fab-button.css';

import './js/global';
import './hinclude/hinclude';
import './components/search/index';
import './core/matomo';
