import Vue from 'vue';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';

const uri = '/fr/search-auto-complete';
const uri_result = '/fr/search-results';


new Vue({
  el: '#search-box',
  components: {
    Autocomplete,
  },
  methods: {
    search(input) {
      const url = `${uri}/${encodeURI(input)}`;

      return new Promise((resolve) => {
        if (input.length < 2) {
          return resolve([]);
        }

        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            resolve(data);
          });
      });
    },

    getResultValue(result) {
      return result.title;
    },

    // Open the selected article in
    // a new window
    onSubmit(result) {
      window.location.href = `${uri_result}/${encodeURI(result.title)}`;
    },
  },
});
